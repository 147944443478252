<template>
  <div>
    <van-cell>
      <template slot="title">
        <span style="color: #57c4b7"
          >*温馨提示：请补全信息，以用于跨院绑定健康卡，请确保以下信息真实有效后再进行提交。</span
        >
      </template>
    </van-cell>   
    <van-list>
      <van-cell-group style="margin-top:10px;">
        <van-cell title="姓名">
          <template #default>
            <span style="margin: auto 6px" :style="!list.userName?{color:'red'}:{color:''}">{{list.userName?list.userName:"未填写"}}</span>
          </template>
          <template #right-icon>
            <van-tag
              color="#57c4b7"
              @click="modify('userName',list.userName, '姓名')"
              >修改</van-tag
            >
          </template>
        </van-cell>
        <van-cell title="身份证号">
          <template #default>
            <span style="margin: auto 6px" :style="!list.idCard?{color:'red'}:{color:''}">{{list.idCard?list.idCard:"未填写"}}</span>
          </template>
          <template #right-icon>
             <van-tag
              color="#57c4b7"
              @click="modify('idCard',list.idCard, '身份证号')"
              >修改</van-tag
            >
          </template>
        </van-cell>
       
        <van-cell title="民族">
          <template #default>
          <span style="margin: auto 6px" :style="!nation?{color:'red'}:{color:''}">{{nation?nation:"未填写"}}</span>
          </template>
        <template #right-icon>
             <van-tag
              color="#57c4b7"
              @click="modify('nation',nation, '民族')"
              >修改</van-tag
            >
          </template>
        </van-cell>
        <van-cell title="性别">
          <template #default>
            <span style="margin: auto 6px" :style="!Sex?{color:'red'}:{color:''}">{{Sex?Sex:"未填写"}}</span>
          </template>
        <template #right-icon>
            <van-tag
              color="#57c4b7"
              @click="modify('Sex',Sex, '性别')"
              >修改</van-tag
            >
          </template>
        </van-cell>
        <van-cell title="手机号码">
          <template #default>
            <span style="margin: auto 6px" :style="!list.phone?{color:'red'}:{color:''}">{{list.phone?list.phone:"未填写"}}</span>
          </template>
          <template #right-icon>
            <van-tag
              color="#57c4b7"
              @click="modify('phone', list.phone, '手机号码')"
              >修改</van-tag
            >
          </template>
        </van-cell>
       
        <van-cell title="出生日期">
          <template #default>
          <span style="margin: auto 6px" :style="!Birthday?{color:'red'}:{color:''}">{{Birthday?Birthday:"未填写"}}</span>
          </template>
          <template #right-icon>
         <van-tag
              color="#57c4b7"
              @click="modify('Birthday',Birthday, '出生日期')">修改</van-tag>
          </template>
        </van-cell>
           <!-- <van-cell style="padding:0 30px;">
             <span style="float:right;color:blue;font-size:14px;text-decoration:underline;
             padding: 10px 0px;" @click="getTextCode">已有健康卡，前往一键关联</span>
        </van-cell> -->
      </van-cell-group>
      <van-button style="display:block;margin:15px auto;" color="#57c4b7" type="primary" @click="input">确认提交</van-button>
      
    </van-list>
    <van-dialog
      v-model="showDialog"
      :title="'修改' +  upData.title"
      show-cancel-button
      @confirm="onConfirm"
    >
      <van-cell-group>
        <van-field
          v-model=" upData.value"
          :label="upData.title"
          :placeholder="'请输入' + upData.title"
        />
      </van-cell-group>
    </van-dialog>
    <div
      style="
        font-size: 12px;
        color: #d3d3d3;
        margin: 1% 40%;
        width: auto;
        height: 40px;
        text-align:center
      "
    >
      <span>&copy;拓实银医</span>
    </div>
    <!-- 开局遮罩层 -->
    <van-popup v-model="show" :close-on-click-overlay="false" >
      <div style="background-color:rgba(0, 0, 0, 0.7);">
         <van-loading size="24px" vertical color="#0094ff">加载中...</van-loading>
      </div>  
        
    </van-popup>
  </div>
</template>

<script>
import "@/assets/iconfont/write/iconfont.css";
import ajax from "../../lib/ajax";
// import store from "../../store";
// const hospitalld="34742";
// import { Dialog } from 'vant';
import { Toast } from "vant";
export default {
  data() {
    return {
      show:false,
      nation:"",//民族
      qrCodeText:"",
      wechatCode:"",//微信授权码
      openid:"",
      userId: 0,
      showDialog:false,
      Sex: "",
      Birthday: "",
      list: [],
     card:{},//健康卡
     upData:"",//更新信息
     healthCardId:"",//健康卡id
    };
  },
  created() {
     let openid =  sessionStorage.getItem('openid'); 
     console.log(openid)
     this.openid=openid;
     let wechatCode = this.$route.query.wechatCode;
     this.wechatCode=wechatCode;
    this.onload();
  },
  methods: {
    onload() {
      // let lists = [];
           //获取用户信息
             let idCard = sessionStorage.getItem('showid'); 
       ajax
        .post("/Api/CardService/WeChatGetOwnerMembers?input=" + this.openid)
        .then((res) => {
          res.data.result.forEach(el => {
            console.log(idCard)
             if(idCard==el.idCard){
               this.list=el;
                // this.show=false;
             }
          });
          let c = this.list.idCard;
              let year = c.substring(6, 10);
              let month = c.substring(10, 12);
              let day = c.substring(12, 14);
              let sex = c.substring(16, 17);
              this.Sex = sex % 2 > 0 ? "男" : "女";
              this.Birthday = year + "-" + month + "-" + day;
              console.log(this.list);
              // 去遮罩层
             
          // console.log(uData);
              // lists.push(date[i]);
        })
      
    },
    
  
    //注册
    input(){
               let phoneReg=/1[3-9]\d{9}/;
                let idCardReg=/^[1-9]\d{5}(18|19|20)\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/;
                 let BirthdayReg=/[0-9]{4}-[0-9]{2}-[0-9]{2}/;
              if(!this.list.userName){
                Toast.fail('姓名未填写');
                       return;
              }else if(!this.list.idCard){
                  Toast.fail('身份证号未填写');
                       return;
              }else if(!idCardReg.test(this.list.idCard)){
                  Toast.fail('身份证号格式错误');
              }
              else if(!this.nation){
                Toast.fail('民族未填写');
                       return;
              }else if(!this.Sex){
                  Toast.fail('性别填写未填写');
                       return;
              }
              
              else if(!this.list.phone){
                
                 Toast.fail('电话未填写');
                       return;
              }else if(!phoneReg.test(this.list.phone)){
                         Toast.fail('电话格式错误');
              }
              else if(!this.Birthday){
                   Toast.fail('出生日期未填写');
                       return;
              } else if(!BirthdayReg.test(this.Birthday)){
                   Toast.fail('出生日期格式错误：xxxx-xx-xx');
                       return;
              }
              let userName=this.list.userName;
              let phone=this.list.phone;
              let c = this.list.idCard;
             
              let subData={
                          birthday:  this.Birthday,
                          idType: "01",
                          gender: this.Sex,
                          idNumber: c,
                          name: userName,
                          nation: this.nation,
                          phone1: phone,
                          wechatCode:this.wechatCode,
                  }
                //  console.log(subData);
                
                ajax.post("/Api/HealthCardService/RegisterHealthCard",subData).then((res)=>{
                // console.log(res)
                  this.healthCardId=res.data.result.rsp.healthCardId;
                  this.qrCodeText=res.data.result.rsp.qrCodeText;
                  console.log(res.data.result.commonOut.errMsg)
                   if(res.data.result.commonOut.errMsg=="成功"){
                    //  this.show=true;
                       
                    //  this.$router.push("/HcCode");
                            this.bind();
                  
                    
                   }else{
                      Toast.fail(res.data.result.commonOut.errMsg);
                   }
                
                }).catch((err)=>{
                  console.log(err);
                   Toast.fail('注册失败，请检查wechatCode');
                })
    },
    // //获取二维码
    // getTextCode(){
    //   let redirect_uri="http://wx.fgxrmyy.cn/HcCode";
    //  window.location.href=`https://health.tengmed.com/open/getHealthCardList?redirect_uri=${redirect_uri}&hospitalId=${hospitalld}`;
    // },
    //修改按钮
    modify(type,value,title){
       this.upData={type,value,title};
       this.showDialog=true;
    },
    //确认修改
    onConfirm(){
       if(this.upData.type=="userName"){
         this.list.userName=this.upData.value;
       }else if(this.upData.type=="phone"){
         this.list.phone=this.upData.value;
       }
       else if(this.upData.type=="idCard"){
         this.list.idCard=this.upData.value;
           let c = this.list.idCard;
              let year = c.substring(6, 10);
              let month = c.substring(10, 12);
              let day = c.substring(12, 14);
              let sex = c.substring(16, 17);
              this.Sex = sex % 2 > 0 ? "男" : "女";
              this.Birthday = year + "-" + month + "-" + day;
       }
       else if(this.upData.type=="Sex"){
         this.Sex=this.upData.value;
       }
       else if(this.upData.type=="Birthday"){
         this.Birthday=this.upData.value;
       }
       else if(this.upData.type=="nation"){
         this.nation=this.upData.value;
       }
    },
     bind(){
        // ajax.get(`/Api/HealthCardService/BindCardRelation?Patid=530125200201171512&QrCodeText=189DA34C1BC5E55A2585DA0867AD17C6477F51BBC45A5940F75DEE9C83474A23:0:D660E98498E03EB4C4A4FDFAAD1EBF92:5300A0069FWHX0002&AppToken=F44AF8513DEAB893A6447ECE90299CB5`)
       ajax.get(`/Api/HealthCardService/BindCardRelation?QrCodeText=${this.qrCodeText}&Patid=${this.list.idCard}`)
      .then((res)=>{
        console.log(res)
        console.log("开始绑定了");
        if(res.data.result.commonOut.errMsg=="成功"){
         console.log("我开始了");

          //  ajax.get(`/Api/CardService/UpdateBarCode?input=530125200201171512&newBarCode=189DA34C1BC5E55A2585DA0867AD17C6477F51BBC45A5940F75DEE9C83474A23`)
          ajax.get(`/Api/CardService/UpdateBarCode?input=${this.list.idCard}&newBarCode=${this.healthCardId}&openid=${this.openid}`)
          .then((res)=>{
         
          
          if(res.data.result>0){
            Toast.success('绑定成功');
            this.$router.replace({path:"/healcard"}).catch((err)=>{console.log(err)})
          }
            
          })
          .catch(()=>{
            // this.show=false;
            Toast.fail('服务器错误');
            
            
          })
        }else{
          Toast.fail('绑定失败,请核对身份信息是否正确');
          
         
        }
    
      }).catch(()=>{
        //  this.show=false;
         Toast.fail('参数错误');
      })
    },
  },
  
}; 
</script>

<style>
</style>
